<template>
  <div class="domain-detail-dns">
    <!--    <page-block v-if="hasToolNew" :title="$t('add.title')">-->
    <!--      <transition name="fade" mode="out-in">-->
    <!--        <base-loader v-if="addIsLoading" />-->
    <!--        <main-card v-else-if="config">-->
    <!--          <base-stack-collapser :more="$t('add.collapse')">-->
    <!--            <div>-->
    <!--              <order-config-->
    <!--                :period="12"-->
    <!--                :configurator="config"-->
    <!--                @init="onAddInit"-->
    <!--                @ready="onAddReady(true, $event)"-->
    <!--                @notready="onAddReady(false, $event)"-->
    <!--                @change="onAddChange"-->
    <!--              />-->
    <!--              &lt;!&ndash;                slot="footerEnd"&ndash;&gt;-->
    <!--              <base-button-->
    <!--                :disabled="!addIsValid"-->
    <!--                :loading="addIsSending"-->
    <!--                class="domain-detail-dns__btn"-->
    <!--                @click="sendNew"-->
    <!--              >-->
    <!--                {{ $t('add.btn') }}-->
    <!--              </base-button>-->
    <!--            </div>-->
    <!--          </base-stack-collapser>-->
    <!--        </main-card>-->
    <!--      </transition>-->
    <!--    </page-block>-->
    <page-block
      :title="$t('list.title')"
      :style="{ width: tableWidth ? tableWidth + 'px' : '100%' }"
      class="domain-detail-dns__block"
    >
      <base-button v-if="hasToolNew" @click="addDomain">
        {{ $t('add.btn') }}
      </base-button>
      <transition name="fade" mode="out-in">
        <base-loader v-if="isLoading" />
        <base-table
          v-else-if="sortedList.length"
          :list="sortedList"
          :columns="tableHead"
          :sort="sort"
          class="domain-detail-dns__table standart-text"
          @sort-change="onSortChange"
        >
          <dns-record-table-row
            v-for="(item, i) in sortedList"
            :key="i"
            :item="item"
            :columns="tableHead"
          />
        </base-table>
        <base-empty
          v-else-if="sortedList.length === 0 && tariff.dtype === 'slave'"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          class="ssl-info__empty"
        />
      </transition>
    </page-block>
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import DnsRecordTableRow from '../../../components/DnsRecordTableRow.vue';
import { DnsHost } from '@/models/DnsMgr/DnsHost';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import showErrorModal from '@/mixins/showErrorModal';
// import BaseStackCollapser from '@/components/BaseCollapser/BaseStackCollapser.vue';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty.vue';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';
import { isEqual } from 'lodash';
export default {
  name: 'DomainDetailDns',
  components: {
    BaseEmpty,
    BaseTable,
    DnsRecordTableRow,
  },
  // BaseStackCollapser, OrderConfig,

  mixins: [showErrorModal],
  props: {
    tariff: {
      type: DnsHost,
      required: true,
      validator: obj => obj instanceof DnsHost,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      tableHeadMain: [
        {
          key: 'type',
          label: this.$t('list.tableHead.type'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'rtype_orig',
            order: 'asc',
          },
        },
        {
          key: 'host',
          label: this.$t('list.tableHead.host'),
          style: {
            flex: '1 1 300px',
            width: '300px',
            minWidth: '300px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'value',
          label: this.$t('list.tableHead.value'),
          style: {
            width: '290px',
            maxWidth: '290px',
          },
          sort: {
            prop: 'value',
            order: 'asc',
          },
        },
        {
          key: 'ttl',
          label: this.$t('list.tableHead.ttl'),
          style: {
            width: '70px',
          },
          sort: {
            prop: 'ttl',
            order: 'asc',
          },
        },
        {
          key: 'info',
          label: this.$t('list.tableHead.info'),
          style: {
            width: '250px',
            maxWidth: '250px',
          },
        },
      ],
      tableHeadMore: [
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      tableWidth: 0,
      resizeObserver: null,
      config: null,
      addFormData: {},
      addIsValid: false,
      addIsSending: false,
      addIsLoading: false,
    };
  },
  computed: {
    current() {
      return this.tariff;
    },
    moduleState() {
      const splitted = this.moduleMain.split('.');
      const state = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return state;
    },
    modulePath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    tools() {
      return this.moduleState.tools;
    },
    dnsMgrTools() {
      return this.moduleState.dnsMgrTools;
    },
    toolRecord() {
      return this.dnsMgrTools.record;
    },
    sort() {
      return this.moduleState.moduleDnsRecords.sort;
    },
    sortedList() {
      return this.$store.getters[`${this.modulePath}/moduleDnsRecords/GET_SORTED_LIST`];
    },
    isLoading() {
      return this.moduleState.moduleDnsRecords.isLoading;
    },
    recordTools() {
      return this.moduleState.moduleDnsRecords.tools;
    },
    tableHead() {
      const arr = [...this.tableHeadMain];
      if (this.recordTools.edit || this.recordTools.delete) arr.push(...this.tableHeadMore);
      return arr;
    },
    toolNew() {
      if (!this.recordTools || !this.recordTools.new) return null;
      return this.recordTools.new;
    },
    hasToolNew() {
      return this.toolNew && this.toolNew.isEnable(this.current);
    },
  },
  watch: {
    toolRecord(tool) {
      if (tool && tool.isEnable(this.current)) this.fetchList();
    },
    toolNew(tool) {
      if (tool && tool.isEnable(this.current)) this.fetchNew();
    },
    // 'addFormData.value': function (event) {
    //   if (this.addFormData && this.addFormData.rtype === 'txt') {
    //     // console.log(event);
    //     this.addFormData.value.replaceAll('\n', '');
    //     console.log(this.addFormData.value);
    //   }
    // },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(entries => {
      this.setTableWidth(entries[0].contentRect.width);
    });
    this.resizeObserver.observe(this.$el.offsetParent);
    this.updateSort(this.sort);
    if (this.dnsMgrTools) {
      if (this.toolNew && this.toolNew.isEnable(this.current)) this.fetchNew();
      if (this.toolRecord && this.toolRecord.isEnable(this.current)) this.fetchList();
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$el.offsetParent);
      this.resizeObserver = null;
    }
  },
  methods: {
    async addDomain() {
      this.addIsLoading = true;
      this.showLoadingModal();
      let params = {
        func: this.toolNew.func,
        plid: this.current.elid,
        auth: this.current.auth,
      };
      const data = await this.getAddForm(this.toolNew, params);
      if (data === 'cancel' || data === 'fail') this.$modals.close(this.modal.name);
      else {
        const res = await this.sendAddForm(this.toolNew, data);
        if (res === 'fail') this.addDomain();
        this.showResModal(res);
      }
      return this.$store
        .dispatch(`${this.modulePath}/moduleDnsRecords/fetchDnsMgrToolAction`, params)
        .then(data => {
          const { fields, model, hidefields, slist } = data;
          this.config = new BaseConfigurator({
            customfields: fields,
            model,
            slist,
            hidefields,
          });
        })
        .finally(() => (this.addIsLoading = false));
    },
    getAddForm(tool, params = {}) {
      return new Promise(resolve => {
        if (!tool || !(tool instanceof BillMgrTool || tool instanceof DnsMgrTool)) resolve('fail');
        const func =
          tool instanceof BillMgrTool ? 'fetchBillMgrToolAction' : 'fetchDnsMgrToolAction';
        this.$store
          .dispatch(`${this.modulePath}/${func}`, params)
          .then(async data => {
            const { fields, hidefields, model, slist } = data;
            const config = new BaseConfigurator({
              customfields: fields,
              hidefields,
              model,
              slist,
            });
            if (
              config._info.customfields &&
              config._info.customfields.name &&
              config._info.customfields.name.required
            ) {
              config._info.customfields.name.required = 'no';
            }
            if (
              config._info.customfields &&
              config._info.customfields.name &&
              config._info.customfields.name.hint
            ) {
              config._info.customfields.name.hint = 'Имя записи (должно содержать @ или поддомен)';
            }
            const res = await this.showEditModal(config);
            if (res === 'cancel') resolve('cancel');
            else if (res === 'fail') resolve('fail');
            else {
              params = { ...params, ...res };
              resolve(params);
            }
          })
          .catch(e => {
            this.showError(e);
            resolve('fail');
          });
      });
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(config, props = {}) {
      return new Promise(resolve => {
        if (!config || !(config instanceof BaseConfigurator)) resolve('fail');
        if (!this.modal) this.makeModal(props);
        let formData = {};
        let formDataBackup = null;
        let isReady = false;
        Vue.set(this.modal, 'props', { period: 12, configurator: config });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
          },
          ready: data => {
            Object.assign(formData, data);
            isReady = true;
            if (!isEqual(formData, formDataBackup)) {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            }
          },
          notready: data => {
            Object.assign(formData, data);
            isReady = false;
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            Object.assign(formData, data);
            let hasChanges = !isEqual(formData, formDataBackup);
            Vue.set(this.modal.footer.confirm.props, 'disabled', !hasChanges || !isReady);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closeOnBackdrop', false);
        // Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: { title: this.$t('add.submit'), disabled: true },
            on: { click: () => resolve(formData) },
          },
          cancel: {
            props: { title: this.$t('add.cancel') },
            on: { click: () => resolve('cancel') },
          },
        });
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    sendAddForm(tool, payload = {}) {
      this.addIsSending = true;
      if (payload && payload.name === '') {
        payload.name = '@';
      }

      const params = {
        func: this.toolNew.func,
        plid: this.current.domain,
        auth: this.current.auth,
        ...payload,
      };
      return new Promise(resolve => {
        this.$store
          .dispatch(`${this.modulePath}/moduleDnsRecords/sendDnsMgrToolAction`, params)
          .then(data => {
            if (data.ok) {
              resolve('success');
              Promise.all([this.fetchNew(), this.fetchList(true)]);
            } else resolve('fail');
          })
          .catch(e => {
            this.showError(e);
            resolve('fail');
          });
        // .finally(() => {
        //   this.addIsSending = false;
        //   this.addIsValid = false;
        // });
      });
    },
    fetchList(update = false) {
      const action = update ? 'updateList' : 'fetchList';
      const params = {
        func: this.toolRecord.func,
        elid: this.current.domain,
        auth: this.current.auth,
      };
      return this.$store.dispatch(`${this.modulePath}/moduleDnsRecords/${action}`, params);
    },
    fetchNew() {
      this.addIsLoading = true;
      const params = {
        func: this.toolNew.func,
        plid: this.current.elid,
        auth: this.current.auth,
      };
      return this.$store
        .dispatch(`${this.modulePath}/moduleDnsRecords/fetchDnsMgrToolAction`, params)
        .then(data => {
          const { fields, model, hidefields, slist } = data;
          this.config = new BaseConfigurator({
            customfields: fields,
            model,
            slist,
            hidefields,
          });
        })
        .finally(() => (this.addIsLoading = false));
    },
    // sendNew() {
    //   this.addIsSending = true;
    //   if (this.addFormData && this.addFormData.rtype === 'txt') {
    //     //     // console.log(event);
    //     this.addFormData.value = this.addFormData.value.replaceAll('\n', '');
    //     // console.log(this.addFormData.value.replaceAll('\n', ''));
    //   }
    //
    //   const params = {
    //     func: this.toolNew.func,
    //     plid: this.current.domain,
    //     auth: this.current.auth,
    //     ...this.addFormData,
    //   };
    //   this.$store
    //     .dispatch(`${this.modulePath}/moduleDnsRecords/sendDnsMgrToolAction`, params)
    //     .then(data => {
    //       if (data.ok) {
    //         const props = {
    //           title: this.$t('add.success.title'),
    //           text: this.$t('add.success.text'),
    //         };
    //         this.showResult(props);
    //         Promise.all([this.fetchNew(), this.fetchList(true)]);
    //       }
    //     })
    //     .catch(e => this.showError(e))
    //     .finally(() => {
    //       this.addIsSending = false;
    //       this.addIsValid = false;
    //     });
    // },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit(`${this.modulePath}/moduleDnsRecords/SET_SORT`, { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    setTableWidth(value) {
      this.tableWidth = value;
    },
    // onAddInit(data) {
    //   this.addFormData = data;
    // },
    // onAddReady(isValid, data) {
    //   this.addIsValid = isValid;
    //   this.addFormData = { ...data };
    // },
    // onAddChange(data) {
    //   this.addFormData = { ...data };
    // },
    // submitAdd() {
    //   // console.log(this.addFormData);
    // },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal.footer, 'centered', true);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close'), theme: 'filled' },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "add": {
      "title": "Добавить запись",
      "submit": "Подтвердить",
      "cancel": "Отменить",
      "сlose": "Закрыть",
      "btn": "Добавить запись",
      "collapse": "Нажмите, чтобы раскрыть",
      "success": {
        "title": "Успешно",
        "text": "Запись успешно добавлена."
      }
    },
    "empty": {
      "title": "Нет DNS записей",
      "text": "Данные для slave домена до сих пор не загружены с DNS мастера"
    },
    "list": {
      "title": "DNS-записи",
      "tableHead": {
        "type": "Тип",
        "host": "Хост",
        "value": "Значение",
        "ttl": "TTL",
        "info": "Дополнительно"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
.domain-detail-dns {
  //padding-top 1rem;
  &__table {
    margin-top: 1.25rem;
    &-value {
      word-break: break-all;
    }
  }
  //&__block {
  //  padding-top 4rem;
  //}
  &__btn {
    margin-top: 1.25rem;
    float: right;
  }
}
</style>
